<template>
  <v-dialog
    transition="dialog-bottom-transition"
    class="b-dialog"
    :max-width="700"
    :retain-focus="false"
    :value="dialog"
    @input="closeModal(false)"
  >
    <v-card>
      <v-toolbar color="primary" dark>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeModal(false)">
          <v-icon>fa-solid fa-xmark</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="b-resume-modal">
        <v-avatar size="80" class="b-resume-modal--avatar">
          <v-icon color="primary" size="36">fa-solid fa-user-tag</v-icon>
        </v-avatar>
        <div class="b-resume-modal__content">
          <v-row dense>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
              <ul>
                <li>
                  <h4>{{ model.name }}</h4>
                  <span>{{ formatCpf(model.document) }}</span>
                </li>
              </ul>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
              <ul>
                <li>
                  <h5 class="primary--text">Plano odontológico</h5>
                </li>

                <li>
                  <label>Plano</label>
                  <span>
                    {{ model.dentalPlanName ? model.dentalPlanName : 'N/A' }}
                  </span>
                </li>
                <li>
                  <label>Carteirinha</label>
                  <span>
                    {{
                      model.dentalPlanCardNumber
                        ? model.dentalPlanCardNumber
                        : 'N/A'
                    }}
                  </span>
                </li>
                <li>
                  <label>Início da Vigência</label>
                  <span>
                    {{
                      model.dentalEffectiveDate
                        ? formatDate(model.dentalEffectiveDate)
                        : 'N/A'
                    }}
                  </span>
                </li>
              </ul>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
              <ul>
                <li>
                  <h5 class="primary--text">Plano de saúde</h5>
                </li>

                <li>
                  <label>Plano</label>
                  <span>
                    {{ model.healthPlanName ? model.healthPlanName : 'N/A' }}
                  </span>
                </li>
                <li>
                  <label>Carteirinha</label>
                  <span>
                    {{
                      model.healthPlanCardNumber
                        ? model.healthPlanCardNumber
                        : 'N/A'
                    }}
                  </span>
                </li>
                <li>
                  <label>Início da Vigência</label>
                  <span>
                    {{
                      model.healthEffectiveDate
                        ? formatDate(model.healthEffectiveDate)
                        : 'N/A'
                    }}
                  </span>
                </li>
              </ul>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatCpf, formatDate } from '@/helpers/formatting';

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    model: {
      type: Object,
      required: true
    }
  },

  methods: {
    formatCpf,
    formatDate,

    closeModal() {
      this.$emit('closeModal');
    }
  }
};
</script>
